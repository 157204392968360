import Header from './components/Header'
import Siteinfo from './components/Siteinfo'
import Artwork from './components/Artwork'
import Footer from './components/Footer'

export default function AppDetail() {
  return (
    <>
    <Header/>
    <Siteinfo />
    <Artwork />
    <Footer />
    </>
  );
}
