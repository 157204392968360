/* This example requires Tailwind CSS v2.0+ */
export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="p-4 bg-white rounded-lg shadow md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800">
      <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© {currentYear} A Monk's Art. An NFT Collection: Who am I from A Monk's Art. All Rights Reserved.
    </span>
    </footer>
  )
}
