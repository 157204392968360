import siteinfo from '../data/siteinfo.json'

export default function Siteinfo() {
  let dAppInfoObj= siteinfo;
  return (
    <div className="py-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base text-gray-400 font-semibold tracking-wide uppercase">From "{dAppInfoObj.attributes[0].value}"</h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-harvest-gold-800 sm:text-4xl">
            {dAppInfoObj.collection} Collection
          </p>
          <p className="mt-4 mb-10 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            {dAppInfoObj.description}
          </p>
        </div>
      </div>
    </div>
  )
}
