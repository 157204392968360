import Header from './components/Header'
import Siteinfo from './components/Siteinfo'
import Collection from './components/Collection'
import Footer from './components/Footer'

export default function AppDetail() {
  return (
    <>
    <Header/>
    <Siteinfo />
    <Collection />
    <Footer />
    </>
  );
}
