import jsondata from '../data/siteinfo.json'
import {WaterfallGrid} from 'react-waterfall-grid'

export default function Collection() {
  let obj;
  let tokenId;
  let collections=[];
  let start = parseInt(jsondata.count);
  let end = parseInt(jsondata.tokenStartID);
  // Call an external API endpoint to get posts
  for (let i=start-1; i>=end; i--) {
    tokenId = i;
    obj={
      "id":tokenId,
      "name":jsondata.collection+' #'+String(tokenId),
      "imageAlt":jsondata.collection+' #'+String(tokenId),
      "imageSrc":'/thumbnails/'+tokenId+".png",
      "href":'/'+String(tokenId)+' '
    };
    collections.push(obj);
  }
  const imagesList = collections.map((callout, idx) => (
    <div key={idx} className="rounded group relative ml-4 mr-4 mb-4 mt-4 shadow-md">
      <div className="rounded-t-lg relative w-full h-80 bg-white overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-120 lg:aspect-w-1 lg:aspect-h-1">
        <img
          src={callout.imageSrc}
          alt={callout.imageAlt}
          className="w-full h-full object-center object-cover"
        />
      </div>
      <h3 className="text-center mt-6 text-sm text-gray-500">
        <a href={callout.href}>
          <span className="absolute inset-0" />
          <p className="text-base mb-6 text-sm font-semibold text-gray-900">{callout.name}</p>
        </a>
      </h3>
    </div>
  ));

  return (
    <div className="bg-gray-100">
      <div className="w-full mx-auto px-4 sm:px-6 lg:px-8">
      <WaterfallGrid childWidth={200} styleGridContainer={{ padding: "10x", width: "100%", position: "relative", justifyContent: "center", zIndex: 1 }}>
          {imagesList}
      </WaterfallGrid>
      </div>
    </div>
  )
}
