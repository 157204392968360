import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import siteinfo from '../data/siteinfo.json'
import ProgressiveImage from "react-progressive-image-loading";
import abi from '../data/amonksart_721.json';
import { ethers } from 'ethers';
import Web3 from 'web3'

export default function Artwork() {
  let [product, productSet] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  let [ownerofToken,ownerofTokenSet]= useState(null);
  let [withaccount, withaccountSet] = useState(false);
  let [tokenminted, tokenmintedSet] = useState(false);
  const [isloading, isloadingSet] = useState(false);
  const contractAddress= siteinfo.contract;
  const [validChainId, setValidChainId] = useState(false);

  // const { contract } = useContract("0xF4074265911E15bb3A2e0AddDBd46f38D9b3DB95");
  const { id } = useParams();
  const nfturi="/tokens/"+id;
  useEffect(() => {
      (async () => {
          let response = await fetch(nfturi);
          let nftinfo = await response.json();
          nftinfo.breadcrumbs=[
            {"id":nftinfo.tokenID, "name": siteinfo.collection, "href":"/"},
          ]
          nftinfo.imageSrc = '/images/'+(id)+".png";
          productSet(nftinfo);
          if (window.ethereum) {
            window.ethereum.on("accountsChanged", accountsChanged);
            window.ethereum.on("chainChanged", chainChanged);
            connectHandler();
          }
      })();
  }, [nfturi, id])

  const connectHandler = async () => {
    if (window.ethereum) {
      try {
        const res = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        await accountsChanged(res[0]);
        if ( window.ethereum.chainId === "0x491ce9c1" || window.ethereum.chainId === "0x89" || window.ethereum.chainId === "0x4"){
          setValidChainId(true);
        }
        else {
          setValidChainId(false);
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      console.log("Install MetaMask");
    }
  };

  const redirectFunction = (tokenID) => {
    let contractAddress= getcontractAddress();
    let mintTokenID=getMintTokenID(tokenID);
    // let domain=(window.ethereum.chainId === "0x89")?"opensea":"opensea";
    // let network=(window.ethereum.chainId === "0x89")?"matic":"matic";
    var url= "https://opensea.io/assets/matic/"+contractAddress+'/'+mintTokenID;
    window.open(url, '_blank');
  }

  const accountsChanged = async (newAccount) => {
    withaccountSet(true);
    let account = await loadWeb3();
    window.contract = await loadContract(account);
    let minTokenID=getMintTokenID (id);
    window.contract.methods.ownerOf(minTokenID).call().then((ownerofToken)=>{
      tokenmintedSet(true);
      ownerofTokenSet(ownerofToken.substr(0,10));
    }).catch(error => {
      tokenmintedSet(false);
      ownerofTokenSet(null);
    })
  };

  const chainChanged = async (res) => {
    await connectHandler();
  };

  const getcontractAddress=() => {
    var contractAddress;
    switch(window.ethereum.chainId) {
    default:
    case "0x89":
      contractAddress=siteinfo.contractPolygon
      break;
    }
    return contractAddress;
  }

  const loadContract = async (address) => {
      let contractAddress= getcontractAddress();
      return await new window.web3.eth.Contract(abi, contractAddress, {
        from: address,
        gasLimit: 3000000,
      });
  }

  async function loadWeb3() {
      if (window.ethereum) {
          window.web3 = new Web3(window.ethereum);
          window.ethereum.enable();
          await window.ethereum.send("eth_requestAccounts");
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const account = await signer.getAddress();
          return account;
      }
  }
  function getMintTokenID (tokenID) {
    return parseInt(tokenID);
  }
  const mint = async(tokenID)  => {
    setErrorMessage("");
  }
  return (
    <>
    {product ? (
      <section>
        <div className="relative max-w-screen-xl px-4 py-4 mx-auto">
          <div className="relative max-w-screen-xl px-4 py-4 mx-auto">
            <nav aria-label="Breadcrumb">
            <ol className="max-w-2xl mx-auto px-4 flex items-center space-x-2 sm:px-6 lg:max-w-7xl lg:px-2">
              {product.breadcrumbs.map((breadcrumb) => (
                <li key={breadcrumb.id}>
                  <div className="flex items-center">
                    <a href={breadcrumb.href} className="mr-2 text-sm font-medium text-gray-900">
                      {breadcrumb.name}
                    </a>
                    <svg
                      width={16}
                      height={20}
                      viewBox="0 0 16 20"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      className="w-4 h-5 text-gray-300"
                    >
                      <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                    </svg>
                  </div>
                </li>
              ))}
              <li className="text-sm">
                <a href={product.href} aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                  {product.name}
                </a>
              </li>
            </ol>
            </nav>
          </div>
          <div className="grid items-start grid-cols-1 gap-8 md:grid-cols-2 sm:px-6 lg:max-w-7xl lg:px-6">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-1">
              <div className="aspect-w-1 aspect-h-1">
                <ProgressiveImage
                  preview={product.thumbnailSrc}
                  src={product.imageSrc}
                  render={(src, style) =>
                    <img className="object-cover rounded-xl" src={src} style={style} alt={product.name} />}
               />
              </div>
            </div>

            <div className="sticky top-0">
              <div className="flex justify-between mt-2">
                <div className="max-w-[35ch]">
                  <h1 className="text-2xl font-bold">
                    {product.name}
              </h1>
            </div>

            <p className="text-lg font-bold mr-6">
            {errorMessage}
            </p>
              </div>

              {!validChainId? (
                <div className="flex mt-8">
                  <button
                    disabled={isloading}
                    onClick={() => redirectFunction(product.tokenID)}
                    type="submit"
                    className="block w-full px-5 py-3 ml-0 text-xs font-medium text-white bg-gray-600 rounded hover:bg-gray-500"
                  >
                    Make An Offer on OpenSea
                  </button>
                </div>
              ):
                tokenminted? (
                <div className="flex mt-8">
                  <button
                    disabled={isloading}
                    onClick={() => redirectFunction(product.tokenID)}
                    type="submit"
                    className="block w-full px-5 py-3 ml-0 text-xs font-medium text-white bg-gray-600 rounded hover:bg-gray-500"
                  >
                    Owned by {ownerofToken} | Make An Offer on OpenSea
                  </button>
                </div>
              ):
                withaccount? (
                <div className="flex mt-8">
                 {isloading?(
                   <button
                     disabled={isloading}
                     onClick={() => mint(product.tokenID)}
                     type="submit"
                     className="animate-pulse  block w-full px-5 py-3 ml-0 text-xs font-medium text-white bg-harvest-gold-600 rounded hover:bg-harvest-gold-500"
                   >
                     Minting In Progress ...
                   </button>

                 ):(
                   <button
                     disabled={isloading}
                     onClick={() => redirectFunction(product.tokenID)}
                     type="submit"
                     className="block w-full px-5 py-3 ml-0 text-xs font-medium text-white bg-gray-600 rounded hover:bg-gray-500"
                   >
                     Make An Offer on OpenSea
                   </button>
                 )}

                </div>


              ):(
                <div className="flex mt-8">
                  <button
                    disabled={isloading}
                    type="submit"
                    className="block w-full px-5 py-3 ml-0 text-xs font-medium text-white bg-gray-600 rounded hover:bg-gray-500"
                  >
                    Please Connect Metamask First
                  </button>
                </div>
              )}


              <details className="relative mt-4 group ">
                <summary className="block">
                  <div>
                    <div className="prose max-w-none">
                      <p className="text-justify">
                      {product.description}
                      </p>
                    </div>

                  </div>
                </summary>

              </details>

              <form className="mt-8">
                <fieldset>
                  <legend className="mb-1 text-sm font-medium">
                    {product.attributes[0].trait_type}
                  </legend>

                  <div className="flow-root">
                    <div className="flex flex-wrap -m-0.5">
                      <label htmlFor="color_tt" className="cursor-pointer p-0.5">
                        <input type="radio" name="color" id="color_tt" className="sr-only peer" />

                        <span className="inline-block px-3 py-1 text-xs font-medium border rounded-full group peer-checked:bg-black peer-checked:text-white">
                          {product.attributes[0].value}
                        </span>
                      </label>
                    </div>
                  </div>
                </fieldset>

                <fieldset className="mt-4">
                  <legend className="mb-1 text-sm font-medium">
                    {product.attributes[1].trait_type}
                  </legend>

                  <div className="flow-root">
                    <div className="flex flex-wrap m-1">
                      <p className="px-3 py-1 text-xs font-medium border rounded-full group peer-checked:bg-black peer-checked:text-white">
                          {product.attributes[1].value}
                      </p>
                    </div>
                  </div>
                </fieldset>

                <fieldset className="mt-4">
                  <legend className="mb-1 text-sm font-medium">
                   Royalties
                  </legend>

                  <div className="flow-root">
                    <div className="flex flex-wrap m-1">
                      <p className="px-3 py-1 text-xs font-medium border rounded-full group peer-checked:bg-black peer-checked:text-white">
                        2%
                      </p>
                    </div>
                  </div>
                </fieldset>

                <fieldset className="mt-4">
                  <legend className="mb-1 text-sm font-medium">
                   Total Supply
                  </legend>

                  <div className="flow-root">
                    <div className="flex flex-wrap m-1">
                      <p className="px-3 py-1 text-xs font-medium border rounded-full group peer-checked:bg-black peer-checked:text-white">
                        100
                      </p>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </section>
    ):
    (
      <div className="max-w-2xl mx-auto px-4 flex items-center h-48 border-2 rounded-md mb-20">
        <div className="flex animate-pulse flex-row items-center h-full justify-center space-x-5">
          <div className="w-12 bg-gray-300 h-12 rounded-full ">
          </div>
              <div className="flex flex-col space-y-3">
              <div className="w-36 bg-gray-300 h-6 rounded-md ">
              </div>
              <div className="w-24 bg-gray-300 h-6 rounded-md ">
              </div>
          </div>
        </div>
      </div>
    )}
    </>
  )
}
